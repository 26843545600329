/**
targeted to post of pilot type-
*/
body.single-event{
    .section-post-header-container{
        // @extend
      .item-date-container{
        @extend .col-sm-2;
        @extend .col-xs-3;
        font-family: $heading-font-family;
        line-height:4em;
        text-align: center;
        float:right;
        overflow:hidden;
        z-index:2;
        position:relative;

        border:solid 3px black;
        background-color: white;
        padding: 2.7rem 0;
        padding-top: 28px;

        span{
            display:block;
        }

        $tiny-breakpoint: 430px;

        @media (max-width:$tiny-breakpoint){
          line-height: 2rem;
        }
        .weekday{
            font-size:1.2rem;
            font-family: $font-family;
            font-weight: bold;
            @media (max-width:$screen-md){
              font-size: 1rem;
            }
            @media (max-width:$tiny-breakpoint){
              font-size:0.6rem;
            }
        }
        .monthday{
            font-size:5.625rem;
            line-height: 3.8rem;
            padding-bottom: 0.3rem;
            @media (max-width:$tiny-breakpoint){
              font-size:2.5rem;
            }
        }
        .month{
            font-size: 3.125rem;
            text-transform: uppercase;
            @media (max-width:$tiny-breakpoint){
              font-size:1.2rem;
            }
        }

      }

      &>.item-post-data-container{
          @extend .col-xs-9;
      }

      .item-facilitators-container{
          list-style-type: none;
          list-style-image: none;
          padding-left: 0px;
          li{
            padding-left: 0px;
          }
      }

      .items-categories-wrapper{
//          min-height: 4em;
      }

    }
    .section-post-thumbnail-container{
        max-height: 600px;
        overflow: hidden;
        .post-thumbnail{
            // width:100%;
            min-height: 100%;
            min-width: 100%;
        }
    }


    .item-side-data-container{
        border:solid 3px $blackish;
        padding: 30px;
        .event-coordinates{
          display:block;
//          line-height: 1.8rem;
        }
        h2{
            font-family: $heading-font-family;
            font-size:1rem;
            margin-bottom:1.5em;
            margin-top: 1em;
            text-transform:uppercase;
            font-weight: $heading-font-weight;
            color: $heading-font-color;
        }
        .event-ispublic,.button, .ical-export {
            margin-top: 2em;
            font-weight: 600;
        }
        .button{
            width: 100%;
        }
        .ical-export-button{
          margin-top:53px;
        }
    }


}
