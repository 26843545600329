
@mixin side-thing {
    @extend .col-sm-4;
    @extend .col-xs-12;
    position: relative;
    overflow: hidden;

    @media (max-width:$screen-sm){
      position:unset;
      margin-top: 0;
      margin-bottom: 50px;
    }
}
@mixin side-thing-counterpart{
    @extend .col-sm-7;
    @extend .col-xs-12;
    float: right;
}
@mixin hero-item{

    //we want this element to break out,
    width:100% !important;
    max-width: none;
    //but in order for the contained elements to adjust to the grid,
    //we need to apply the container class to the children.
    //.5 rem. Why? no idea. It misaligns otherwise

    padding: 0 .5rem;
    color:white;

    padding-top: 60px;
    @media (min-width:$screen-sm-min){
      padding-top: 189px;
    }

    padding-bottom: 97px;
    margin-bottom: 60px;

    margin-left:0px;
    margin-right:0px;
}


@mixin remove-list-style(){
    margin: 0;
    padding: unset;
    list-style-type: none;
    list-style-image: none;
    li {
        display: inline;
    }
}
@mixin center-pushed-text($n){
  @extend .col-sm-#{$n};
  @extend .col-sm-push-#{(12 - $n) / 2};
}
