
$item-spacing: 15px;

.section-container .items-pilots-wrapper {
    // @extend .row;

    margin-top: $item-spacing;

    left: -$item-spacing;
    position: relative;
    display: inline-block;
    .categorizer-menu{
      .tag{
        border:none;
        color:gray;
        text-transform: capitalize;
      }
      .tag.active{
        background-color: transparent;
        color: #000;
      }
      .category-menu {
        .tag{
          font-weight: bold;
          line-height: 2.7rem;
        }
      }
      .tag-menu{
        .tag{
          font-size: 0.89rem;
        }
      }
    }
    .item-categorizer-tag-title {
      @include title1();
      font-size: 1.58rem;
      display: inline-block;
      width: 100%;
    }
    hr{
      // margin-bottom: 44px;
      margin-top: 72px;
      width:100%;
      display:inline-block;
    }
    .categorizer-menu{
      margin-bottom: 3rem;
      .count {
        font-size: 0.8em;
        border: solid 1px;
        border-radius: 1rem;
        padding: 0px 7px;
      }
      .category-menu{
        border-top:solid 1px black;
        &:before {
          content: "Browse pilots list by:";
          padding-right: 1rem;
        }
      }
      .tag-menu{
        border-top:solid 1px black;

      }
    }
    &>*{
      padding:$item-spacing;
    }
    .item-pilot-container {
        $borderpx:3;

        @extend .col-md-3;
        @extend .col-sm-6;
        // @extend .col-sm-4;
        @extend .col-xs-12;

        max-width: 400px;

        overflow: hidden;
        display: inline-block;

        padding: $item-spacing;

        // @media (min-width: $screen-lg-min) {
        //   height: 243px;
        // }

        // background-color: #999;
        *{
            text-overflow: ellipsis;
        }

        .item-paragraph-container{
            border: solid #{$borderpx}+px $blackish;

            border: solid 3px #433A3F;
            display: block;
            // height: 182px;
            overflow: hidden;

            .post-image-container{

              //prevents the infuriating content jumping while pictures are loading.
              // @media (max-width:$screen-xs-max){
                // height: 50vw;
                // overflow:hidden;
              // }

              img {
                width: 100%;
              }
            }
            .ellip-line {
                display: inline-block;
                text-overflow: ellipsis;
                white-space: nowrap;
                word-wrap: normal;
            }

            .ellip,
            .ellip-line {
                position: relative;
                overflow: hidden;
                max-width: 100%;
            }
            .post-title-container{
              font-weight: bold;
              font-size: 0.94rem;
              padding: 8px 16px;
              line-height: 1.19rem;

            }
        }
    }
}
