/**
targeted to post of pilot type-
*/




body.single-pilot{
    .item-side-data-container{
      // @include side-thing();
      border:solid 3px $blue;
    }


    .item-side-data-container{
      padding:30px;
      h2{
        color: $blue;
        font-size: 1.05rem;
          margin-bottom:0px;
      }
    }
}
