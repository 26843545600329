/**
these are page specific rules for the pages which
contain a list of items of a certain post type and
a static page. in other words, the "news", "events",
"pilots","home", "jobs", "about"
*/

body.page{

    .section-single-header-container{
        @extend .row;
        .featured-image-container{
            @extend .col-sm-4;
        }
    }

    .section-post-header-container{
        @include hero-item();
        margin-top:0;
        background-color: $yellow;
        position:relative;
        overflow: hidden;
        .deco-noise-picture{
          position:absolute;
          // z-index: 1;
          top:0px;
          left:0px;
          width:100%;
        }
        .item-post-title-container{
            @extend .container;
            position: relative;
            z-index: 2;
            color:#FFF;
            >h1{
              font-size: 1rem;
              font-weight: bold;
              text-transform: none;
              font-family: $font-family;
              color:inherit;
            }
            >p{
              @media (max-width:$screen-sm-min){
                font-size: 2.4rem;
              }
              font-size: 3.4rem;
              line-height: 1em;
              font-weight: 800;
              font-family: $heading-font-family;
              text-transform: uppercase;
              color:inherit;
            }
        }
    }


    .section-post-container{
        @extend .container;
        @extend .row;
        // position: relative;
        .item-post-thumbnail-container,.item-calendar-container{
            @extend .col-sm-4;
            @extend .col-xs-6;
            position: relative;
            overflow: hidden;
            //specially useful for the calendar element:
            min-width:207px;

            margin-top: -150px;
            margin-bottom: 40px;

            padding-left: 0px;
            // position: absolute;
            img{
                position: absolute;
                // right: 0px;
                height: 100%;
                width: unset;
            }
        }
        .item-calendar-container {
            padding: 30px 0px;
        }
        .item-post-thumbnail-container{
            border: solid 3px white;
        }
        .item-post-content-container{
            &:before{
              content: "";
              display: inline-block;
              font: normal normal normal 14px/1 FontAwesome;
              font-size: inherit;
              text-rendering: auto;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              position: absolute;

              left: -4.3vw;
              font-size: 3.6vw;
              opacity: 0.2;
              text-align: center;
              margin-left: 0.3vw;
            }
        }

    }

}
