body.home{
  .section-container{
    p{
      font-size:1.5em;
    }
    p.subtitle-container{
      font-size: 3rem;
      color: white;
      z-index: 3;
      position: relative;
    }
    .button-more{
      font-size: 1rem;
    }
    h2{
      margin-bottom: 29px;
    }
    .button-more{
        @extend .button-list;
        // @extend .glyphicon-arrow-right;
        &::after{
          font-family: "FontAwesome";
          content:$fa-var-long-arrow-right;
          margin-left: 10px;
        }
        text-align: center;
    }
    blockquote{
      @include center-pushed-text(8);
      // font-size: 2rem;
      font-weight: bold;
      float:none;
      // line-height: 1.3;
      color:white;
    }
  }
  .section-callout-container {
      background-color: black;
      color: white;
      padding: 1rem;
      margin: 0;
      width: 100%;
      font-size: .9rem;
      text-align: center;
      max-width: unset;
  }
  .section-post-header-container{
    min-height: 626px;
    overflow: hidden;
    .items-header-wrapper{
      @extend .container;
      @extend .row;
      .item-post-title-container{
        @extend .col-sm-7;
      }
      .item-subscribe-container{
          @extend .col-sm-4;
          // @extend .col-sm-push-8;
          font-size: 0.72rem;
          float: right;
          border: solid 3px black;
          background-color: white;
          color: black;
          text-transform: none;
          position: relative;
          // top: -69px;
          z-index: 10;
          // margin-left: 53px;

          padding: 20px;
          h1,h2{
            color:black;
            text-transform: none;
          }
          #mce-EMAIL{
            margin-right: -4px;
          }
          #mc-embedded-subscribe{
            margin-left: 0px;
          }
          small{
            opacity: 0.6;
          }
      }
    }
    h2{
      text-transform: uppercase;
    }

  }

  .section-post-content-container{
    padding-bottom: 100px;
  }
  .section-news-container{
    .items-news-wrapper{
      padding-top: 12px;
      padding-bottom: 1px;
    //   .item-author-container,.item-title-container,.item-author-container{
    //     padding:10px;
    //   }
    // }
    // p{
    //   @extend .col-sm-8;
    }
    padding-bottom: 100px;
    padding-top: 195px;
  }

  .section-container.section-more-about-container{
    @include hero-item();
    background-color:$blue;
    padding-top: 130px;
    // blockquote{
    //   @extend .col-sm-8;
    //   float:none;
    // }
    // &>*{
    //     @extend .container;
    // }
  }
  .items-pilots-wrapper{
    margin-top:85px;
  }
  .section-events-showcase-container{
      @include hero-item();
      text-align:center;
      background-color: $blackish;
      @media (min-width:$screen-sm-min){
        padding-top: 100px;
      }
      
      h2 {
          margin-bottom: 5.5rem;
      }
      img,.timezone,.hours{
          display: none;
      }
      .item-title-container,.item-date-container{
          color:white;
          display:block;
      }
      .item-title-container{
          font-size: 1.7rem;
          font-weight: bold;
      }
      .item-date-container{
          font-size: 1rem;
      }
      .items-wrapper{
        // margin-top: 99px;
        // padding-bottom: 18px;
        .item-events-container {
          margin-bottom: 4rem;
        }
      }
  }
  .section-news-showcase-container {
    text-align: center;
    .items-wrapper{
      text-align: initial;
    }
  }
  .section-pilots-showcase-container{
    //if you want the section to have a color:
    // background-color: $yellow;
    // width: 100vw;
    // max-width: unset;
    .section-pilots-list-container{
      top: 46px;
      .item-before-container,.item-after-container{
        @include center-pushed-text(8);
        // float:none;
      }
    }
  }
}
