/* Layout

  definitions of spacing for the main container elements.

  =============== */

body{
  text-align: center;
  // overflow-x: hidden;
}
html{
  max-width: 100vw;
  overflow-x: hidden;
}

.main-container {
    /*
    main-container wraps all the page contents
    */
    width: 100%;
    // max-width: 900px;
    text-align: initial;
    padding: 0px;
    margin: 0px;

    // padding-left: 15px;
    // padding-right: 15px;

    // display: inline-block;
    /*dev settings:*/
    background-color: white;
    // overflow: hidden; //this disables stickies
    .section-container{
      .post-thumbnail{
        width:100%;

      }
    }
}
