header.section-container.section-header-container{

  height:$header-menu-height;
  transition: 0.5s;
  // padding-top: 27px;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 100;
  background-color: white;

  margin:0px;
  text-align: center;

  width:100vw;
  max-width: unset !important;

  $sticky-scrolled-menu-height:47px;
  $sticky-scrolled-menu-offset:-32px;
  &.sticky-scrolled{
    //when the view has been scrolled, and it "sticks"
    border-bottom: solid 1px #f1f1f1;

    height: $sticky-scrolled-menu-height - $sticky-scrolled-menu-offset;
    transition: height 0.5s;
    top: $sticky-scrolled-menu-offset;

    .item-header-container img.logo{
      height: 43px;
      transition: 0.5s;

      margin-top: 2px;
    }
    /*if the menu is mobile-collapsed, but active and also is smaller due to scrolling,
    it's offset-top needs to be less to prevent a transparent gap between top-navigation-bar
    and the menu*/
    &.collapsed .main-menu-container > *{
      top: $sticky-scrolled-menu-height;
      transition: top 0.5s;
    }
  }
  .item-header-container{
    @extend .container;

    text-align: left;
    // width: 100%;
    border-bottom: 1px #1f1f1f;
    position:relative;

    img.logo{
      height: 58px;
      transition: 0.5s;

      margin-top:-5px;
    }
    // $main-menu-container-top: ($header-menu-height/2) - 18;

    .title-container{
      display:inline-block;
    }
  }
  &:not(.collapsed) .item-header-container{

    &>.main-menu-container {
      // margin-left: 3em;
      position: absolute;
      right: 0px;
      top: 0.5rem;

      .menu-navigation-container>ul{
        //only firt level menu list
        &>li.menu-item-has-children{
            display:inline-block;
          //only firt level menu item which has children
          &:hover{
            &>ul.sub-menu{
              //only inmediate child of top-level menu
              // transition: top 0.3s;
              top: 1.5rem;
            display: block;
              ul{
                //then all the children menu lists
                top:0px;
                left:0px;

              }
            }
            ul.sub-menu{
              //immediate & non-immediate child menus
              &>li>*{
                color: #969696;
                line-height: 1em;
                text-align: left;
                display: block;
                color: $gray;
                &:hover{
                  color:$blue-light;
                }
              }
            }
            &:after{
              top:10px;
              transition:1s;
              opacity: 0;
            }

          }
          &>ul.sub-menu{
//            left: -2rem;
          }

          &:after{
            @include font-awesome();
            content:$fa-var-angle-down;
            position:relative;
            transition:1s;
            top:0px;

          }

        }
      }

      //any menu list item
      ul {
        //menus and sub-menus at any level
        @include remove-list-style();
        &>li {
          //position:relative;//makes the menu appear right under
            padding-left: 0px;
            margin-left: 1rem;
          a {
              color: #000;
              text-decoration: none;
              // background: pink;
              font-size: 0.875rem;
              font-weight: 500;
              line-height: 16px;
              padding: 7px;
              display: inline-block;
          }
          a:hover{
              // text-decoration: underline;
              color:$blue;
          }
          a:active{
              // text-decoration: underline;
              color:$blue;
          }
          &>ul.sub-menu{
            position: absolute;
          }
          ul.sub-menu{
//            width:400px;
            background-color: white;
            top: -100vh;
              padding: 10px;
              margin-left: -13px;
              display: none;

            // transition: top 1s;
            ul{
              //sorry for the depth.
              //this would be a sub-menu which is not directly a child of the main menu
              //because the direct child of the menu needs not to generate any space,
              //but the "grand" children do need to make space.
              position:relative;
            }
            padding: 6px;
            li{
              display:block;
              padding:6px;
              margin-left: 0px;
              // border-left: solid 2px $blue;
            }
          }

        }
      }
    }
  }


  &.collapsed.active .main-menu-container{
    //active: when the collapsed menu is being displayed
    &:before {
    //   // content: ">>";
    //   font-family: "Glyphicons Halflings";
    //   content: "\e258";
      font-family: 'FontAwesome';
      content:$fa-var-angle-double-right;
      float: right;
    }

    & > .menu-navigation-container>ul {
      ul{
        &>li{
          //stuff gets clearer and smaller consecutively when getting deeper
          opacity:0.5;
          font-size: 0.7em;
        }
      }
    }

    &>*{
      width: 100vw;
      height: 100vh;
      transition:0.2s;
      // left:0;
      // display: block;
      pointer-events:all;
      opacity:1;
    }
  }
  //collapsed: when the screen is small enough to tweak the menu
  &.collapsed .main-menu-container{
    cursor:pointer;
    cursor: pointer;
    top: -0.5rem;



    &:before {
      font-family: 'FontAwesome';
      content:$fa-var-bars;
      // font-family: "Glyphicons Halflings";
      // content: "\e236";//"hamburger"
      // content: "| | |";
      font-size: 28px;
      right: 22px;

      position: absolute;
      top: 18px;
      margin-top: -17px;
    }


    &>*{
        z-index: 1200;
        transition:0.2s;
        background-color: white;

        pointer-events:none;
        opacity:0;
        width: 0;

        position: fixed;
        top: $header-menu-height;
        transition: top 0.5s;

        overflow-x: none;
        overflow-y: auto;

        // position:absolute;

        margin:0px;
        padding:$padding;

        top: $header-menu-height;// - $main-menu-container-top;
        right:0;


        // display:none;

        ul>li{
            // margin: 5vh 2vw;
            font-size: 10vw;
            font-weight: 1;
            text-transform: capitalize;
            display: block;
            width:100%;
        }

    }

  }
}
