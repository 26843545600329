.white-framed{
    overflow: hidden;
    border: solid 3px white;
}
.text-section{
  @include center-pushed-text(8);
}
.full-width{
  width:100% !important;
  height:auto !important;
}
.full-height{
  width:auto !important;
  height:100% !important;
}
.alignright{
  float:right;
}
.alignleft{
  float:left;
}
.disappear{
  opacity: 0;
  width: 0px;
  display: none !important;
  transition: 1s;
}
.tag {
  color:black;
  border: solid 1px;
  margin: 2px;
  padding: 1px 9px;
  display: inline-block;
}
.tag.active {
  background-color:$blue;
  color:white;
}

.button-list{
    color:$red;
    margin-top: 3rem;
    display: block;
    transition: margin-left 0.5s;
    font-weight: 600;
    // @extend .glyphicon-arrow-right;
    &:hover{
      margin-left: 36px;
      transition: margin-left 0.5s;
    }
}
.ical-export-button{
  margin-top:1.7rem;
  margin-bottom:1.7rem;
  display:block;
}
hr{
  display:block;
  margin:50px 0;
}

.button{
  /*
  defined @ base/_variables.scss
  */
}
.button.white{
  background-color: white;
  color: black;
  border: solid 2px black;
  text-transform: none;
  padding: 0.2em 1.2em;
  text-decoration:none;
}

.call-to-action{
  background-color: white;
  border: solid 0.15em black;
  display: inline-block !important;
    a {
     font-weight: bold !important;   
    }
    &:hover {
        border-color:$blue; 
    }
}
