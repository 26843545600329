.section-container{
  .items-wrapper.items-team_members-wrapper{
    @extend .row;
    &>.item-container{
      @extend .col-xs-12;
      @extend .col-sm-4;
      @extend .col-lg-3;

      max-width: 400px;
      margin-bottom: 2rem;

      @media (min-width: $screen-sm-min) {
        height: 390px;
      }
      @media (min-width: $screen-sm-max) {
        height: 444px;
      }

      &>*, a.item-social-media-link-container{
        display:block;
      }
      .contact-info-container,
      .item-social-media-link-container{
        font-style: italic;
      }
      .post-title-container{
        font-weight: bold;
      }
      .member-title-container {
        padding-bottom: 18px;
      }
      .contact-info-container:before{
        // @extend .glyphicon-envelope;
      }
      // .item-social-media-link-container{
      //   @extend .glyphicon-link;
      // }



      .image-container{
        overflow:hidden;
        width:100%;
        background-color: #80808033;
        margin-bottom: 1rem;
        img{
          // max-width: 100%;
        }
      }
    }
    // background-color: yellow;
  }
}
