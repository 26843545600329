
.main-events-container{

  .em-calendar-wrapper, table.em-calendar{
    width:100%;
  }

  table.em-calendar thead{
    height: 4rem;
    td {
      border-bottom: solid 1rem transparent;
      border-top: solid 2rem transparent;
    }
  }

  .em-calendar-wrapper{
      //active cal buttons
      width: 226px;
      right: 0px;
      table.em-calendar{
        td.eventful,  td.eventful-today {
          a{
            color: black;
            text-decoration: none;
          }
          background-color: $gray-light;
        }
        td.eventful-pre {
          background-color: $gray-light;
          a{
            text-decoration: none;
          }
        }

        td.eventless , td.eventless-today {
          background-color: transparent;
        }
        td.eventful-today, td.eventless-today{
          border:solid 1px black;
        }

        .em-calendar * {
            font-weight: 100;
        }
        // td{
        //     border:none;
        // }
      }
    }
    .time-scope-title{
      // font-family: $heading-font-family;
      // color: black;
      // text-transform: uppercase;
      // font-size: 1.9rem;
      @extend h1;
    }
    .items-wrapper{
      .item-events-container{
          @include default-post-item(25px);
          .item-title-container{
              font-weight: 800;
              font-size: 1.25rem;
          }
          .item-date-container{
              .hours{
                  font-size: 1.18em;
              }
              .timezone{
                  display: none;
              }
              .calendar-day{
                font-family: $heading-font-family;
                  display: block;
                  font-size: 2.9em;
                  line-height: 2.4rem;
                  text-transform: uppercase;
                  font-weight: 800;
              }
              .weekday{
                  font-size: 1.18em;
                  display: block;
                  padding-top:22px;
                  font-weight: bold;
              }
          }
      }
      &.items-past-events-wrapper {
        .item-container{
          opacity:0.5;
          transition:opacity 0.2s;
          &:hover{
            opacity: 1;
            transition:opacity 0.2s;
          }
        }
      }
    }


    .item-calendar-container{
      //I would
      // @extend .item-post-thumbnail-container;
      //but for some reason it doesn't allow me to override border color & height
      //see more properties in _page.scss
      border: solid 3px black;
      background-color: white;
      text-align: center;
      min-width: 260px !important;
      // a{
      //     border-bottom:unset;
      // }
      .em-calendar-wrapper {
          position: relative;
          display: inline-block;
      }
    }
    .event-section-container{
      .event-facilitator-list{
          @include remove-list-style();
          @include subtitle();
      }
    }

  }
