/* Variables
 * ===============
 *
 * All variables and most of the configuration is defined on this page. */

/**
import fonts
*/
@import url('https://fonts.googleapis.com/css?family=Poppins:800|Raleway:500,500i,700');
/* Containers */

$header-menu-height: 100px;

$content-box-width: 900px;

$x-small: 600px;
$small: 800px;
$medium: 1000px;
$large: 1200px;

/* Breakpoints */

$mobile: $x-small;
$tablet: $small;
$desktop: $medium;

/* Colors */
$red: #E84351;
$yellow: #F9BF1D;
$blackish: #433A3F;
$blue:#3291CD;
$blue-dark: #00639E;
$blue-light: #6BC0FF;

$background: white;
$primary-color: $blue;
$secondary-color: $red;
$accent-color: $yellow;
$alternate-background: $blackish;
$alternate-color: $blue-dark;

$link-color: $blue;
$link-hover-color: $blue;
$link-decoration: none;
$link-hover-decoration: none;

$highlight: $yellow;
$error: $red;
//bq=blockquote
// $bq-border: 16px solid #f0f0f0;

/* Typography */

html {
    //got ignored:
    // font-size: 18px;
}
// Body font
$font-size: 1rem;
$body-font-size: 1rem;
$font-style: normal;
$font-variant: normal;
$font-weight: normal;
$font-color: black;
$font-family: 'Raleway', sans-serif;
$line-height: 1.6;



// Headings
$heading-font-color: #000;
$heading-font-weight: 600;
$heading-font-family: 'Poppins', sans-serif;
$heading-line-height: 1em;
$heading-margin-bottom: 1em;

// Heading font size
$h1: 1.44rem;//2.25rem;
$h2: 1.16rem;
$h3: 1rem;
$h4: 0.8rem;
$h5: 0.6rem;

@mixin title1() {
    color:$heading-font-color;
    line-height: $heading-line-height;
    font-weight: $heading-font-weight;
    font-family: $heading-font-family;
    font-size:$h1;
    text-transform: uppercase;
    margin:0;
    margin-bottom: $heading-margin-bottom;
    overflow-wrap: break-word;
}

@mixin title2() {
    color: #969696;
    font-size:$h2;
    overflow-wrap: break-word;
}
@mixin title3() {
    font-size:$h3;
    overflow-wrap: break-word;
}
@mixin title4() {
    font-size:$h4;
    overflow-wrap: break-word;
}
@mixin title5() {
    font-size:$h5;
    overflow-wrap: break-word;
}
@mixin subtitle() {
    /*subtitle mixin*/
    font-size: 1.5rem;
    opacity: 0.7;
    font-weight: 600;
    overflow-wrap: break-word;
}

// // Mobile heading font size
// $h1-mobile: 1.75rem;
// $h2-mobile: 1.5rem;
// $h3-mobile: 1.25rem;
// $h4-mobile: 1.1rem;
// $h5-mobile: 1rem;


/* Padding */

$padding: 1rem;
$margins: 1.5rem;
$content-padding: 60px 0;
$content-padding-mobile: 30px 0;

/* Borders */

$border-width: 1px;
$border-style: solid;
$border-color: #dedede;
$border-radius: 4px;
$borders: $border-width $border-style $border-color;

/* Buttons */

$button-background: $primary-color;
$button-background-hover: darken($button-background, 10%);
$button-color: #ffffff;
$button-font-weight: 600;
$button-font-family: inherit;//-apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial, sans-serif;
$button-font-size: 1rem;
$button-border-width: 1px;
$button-border-style: solid;
$button-border-color: $button-background;
$button-border-radius: $border-radius;
$button-text-transform: none;

// Accent buttons
$accent-button-background: $secondary-color;
$accent-button-color: #ffffff;
$accent-button-color-hover: #ffffff;

// Muted Buttons
$muted-border: 1px solid $accent-color;
$muted-border-hover: 1px solid darken($accent-color, 30%);
$muted-background: transparent;
$muted-background-hover: transparent;
$muted-color: darken($accent-color, 50%);
$muted-color-hover: darken($accent-color, 50%);

// Round Buttons
$round-buttons: 40px;

/* Forms */

$forms: ('[type=color], [type=date], [type=datetime], [type=datetime-local], [type=email], [type=month], [type=number], [type=password], [type=search], [type=tel], [type=text], [type=url], [type=week], [type=time], select, textarea');
$buttons: ('.button, a.button, button, [type=submit], [type=reset], [type=button]');
$input-background: transparent;
$placeholder: darken($accent-color, 20%);
$form-border: 1px solid $border-color;
$form-border-hover: 1px solid darken($border-color, 10%);
$form-border-focus: 1px solid $link-color;

/* Tables */

$stripes: #f8f8f8;
$caption: #ababab;

/* Code */

$code-color: $font-color;
$code-size: 14px;
$code-family: Menlo, monospace;
$code-background: transparent;
$code-borders: $borders;
